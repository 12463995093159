import * as React from 'react'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import NotificationsIcon from '@mui/icons-material/Notifications'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import Badge from '@mui/material/Badge'
import Stack from '@mui/material/Stack'
import ListSubheader from '@mui/material/ListSubheader'
import Box from '@mui/material/Box'
import CheckIcon from '@mui/icons-material/Check'
import MenuItem from '@mui/material/MenuItem'
import InfiniteScroll from 'react-infinite-scroll-component'
import CircularProgress from '@mui/material/CircularProgress'
import Chip from '@mui/material/Chip'
import { useSearchParams } from 'next/navigation'
import Controls from '../Controls'
import {
    useQueryAllUserNotifications,
    useMarkAllNotificationsAsRead,
    useMarkAllNotificationsAsSeen,
} from '../../lib/hooks'
import handleError from '../../lib/utils/handleError'
import useToastContext from '../../lib/hooks/useToastContext'
import Notification, { NotificationSkeleton } from './Notification'

const initialCount = 20

const NotificationsList = (): JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const [unreadOnly, setUnreadOnly] = React.useState(false)
    const searchParams = useSearchParams()
    const open = Boolean(anchorEl)
    const {
        data: notificationData,
        status,
        fetchNextPage,
        hasNextPage,
    } = useQueryAllUserNotifications(unreadOnly, initialCount, !searchParams.get('printOnly'))
    const { mutate: markAllAsRead } = useMarkAllNotificationsAsRead()
    const { mutate: markAllAsSeen } = useMarkAllNotificationsAsSeen()
    const toastContext = useToastContext()

    const numOfNotifications = notificationData?.pages.reduce((sum, page) => sum + page.results.length, 0)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
        markAllAsSeen(
            {},
            {
                onError: (error) => handleError(error, toastContext),
            }
        )
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div>
            <IconButton
                size="large"
                edge="end"
                aria-label="notifications"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <Badge
                    badgeContent={notificationData?.pages[0].unseenCount}
                    sx={{
                        '& .MuiBadge-badge': {
                            color: 'common.white',
                            backgroundColor: 'error.light',
                        },
                    }}
                >
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Menu
                sx={{
                    maxHeight: `min(calc(100% - 34px), ${(numOfNotifications || initialCount) * 95}px)`,
                    '.MuiMenu-list': { paddingTop: 0 },
                }}
                PaperProps={{ id: 'scrollableMenu' }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <List
                    disablePadding
                    sx={{
                        width: { xs: '100%', sm: 380 },
                        bgcolor: 'background.paper',
                    }}
                >
                    <ListSubheader
                        sx={{
                            color: 'text.primary',
                            zIndex: 2,
                            paddingTop: 1,
                            paddingX: 0,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingX: 2,
                                gap: 2,
                            }}
                        >
                            <Typography variant="h6">Notifications</Typography>
                            <Box display="flex" alignItems="center" gap={1}>
                                <Chip
                                    color={unreadOnly === false ? 'info' : 'default'}
                                    label="All"
                                    onClick={() => setUnreadOnly(false)}
                                />
                                <Chip
                                    color={unreadOnly === true ? 'info' : 'default'}
                                    label="Unread"
                                    onClick={() => setUnreadOnly(true)}
                                />
                                <Controls
                                    disabled={!(status === 'success' && !!numOfNotifications && numOfNotifications > 0)}
                                >
                                    <MenuItem
                                        onClick={() =>
                                            markAllAsRead(
                                                {},
                                                {
                                                    onError: (error) => handleError(error, toastContext),
                                                }
                                            )
                                        }
                                        sx={{ gap: 1 }}
                                    >
                                        <CheckIcon />
                                        Mark all as read
                                    </MenuItem>
                                </Controls>
                            </Box>
                        </Box>
                        <Divider />
                    </ListSubheader>
                    {status === 'pending' &&
                        Array(8)
                            .fill('')
                            .map((_, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <React.Fragment key={index}>
                                    <NotificationSkeleton />
                                    <Divider component="li" />
                                </React.Fragment>
                            ))}

                    {status === 'success' && numOfNotifications === 0 && (
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            paddingY={5}
                        >
                            <Typography color="text.secondary" variant="h6">
                                No Notifications
                            </Typography>
                        </Box>
                    )}
                    {status === 'success' && !!numOfNotifications && numOfNotifications > 0 && (
                        <InfiniteScroll
                            dataLength={numOfNotifications || initialCount}
                            hasMore={!!hasNextPage}
                            next={fetchNextPage}
                            loader={
                                <Stack direction="row" justifyContent="center" marginTop={1} marginBottom={2}>
                                    <CircularProgress size={30} color="info" />
                                </Stack>
                            }
                            scrollableTarget="scrollableMenu"
                        >
                            {notificationData.pages.map((notifications, i) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <React.Fragment key={i}>
                                    {notifications.results.map((n) => (
                                        <React.Fragment key={n.notificationId}>
                                            <Notification notification={n} handleClose={handleClose} />
                                            <Divider component="li" />
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            ))}
                        </InfiniteScroll>
                    )}
                </List>
            </Menu>
        </div>
    )
}

export default NotificationsList

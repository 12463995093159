import React, { useState } from 'react'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Badge from '@mui/material/Badge'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'
import CommentIcon from '@mui/icons-material/Comment'
import MenuItem from '@mui/material/MenuItem'
import CheckIcon from '@mui/icons-material/Check'
import Skeleton from '@mui/material/Skeleton'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import SportsIcon from '@mui/icons-material/Sports'
import { indigo, orange, grey } from '@mui/material/colors'
import ListIcon from '@mui/icons-material/List'
import GradingIcon from '@mui/icons-material/Grading'
import BinocularIcon from '../icon/BinocularIcon'
import { fromNow } from '../../lib/utils/formatters'
import Link from '../Link'
import Controls from '../Controls'
import {
    useBreakPoints,
    useGetKnicks,
    useGetWestchester,
    useMarkNotificationAsRead,
    useMarkNotificationAsUnread,
} from '../../lib/hooks'
import TeamLogo from '../stats/StatsTeamLogo'

type NotificationProps = {
    notification: DTO.Notification
    handleClose: () => void
}

type BadgeAvatarProps = { content: string | JSX.Element; badgeContent: JSX.Element | null }

const iconStyles = {
    width: 24,
    height: 24,
    border: `2px solid`,
    borderColor: 'background.paper',
    borderRadius: '50%',
    color: 'white',
    padding: '3px',
}

const BadgeAvatar = ({ content, badgeContent }: BadgeAvatarProps) => (
    <Stack direction="row" spacing={2}>
        <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={badgeContent}
            max={99}
        >
            <Avatar>{content}</Avatar>
        </Badge>
    </Stack>
)

const Notification = ({ notification, handleClose }: NotificationProps): JSX.Element => {
    const { category, readAt, recipient, createdAt, content, actionUrl, author } = notification
    const { data: nyk } = useGetKnicks()
    const { data: westchester } = useGetWestchester()
    const [showControls, setShowControls] = useState(false)
    const { isDesktop } = useBreakPoints()
    const { mutate: markAsRead } = useMarkNotificationAsRead()
    const { mutate: markAsUnread } = useMarkNotificationAsUnread()
    const initials = author
        ? author
              .split(' ')
              .map((n) => n[0])
              .join('')
              .toUpperCase()
        : ''

    const notificationIcon = (notificationCategory: Enum.NotificationCategory): JSX.Element => {
        if (notificationCategory === 'INTEL')
            return <PhoneInTalkIcon sx={{ ...iconStyles, backgroundColor: 'post.intel' }} />
        if (notificationCategory === 'SCOUTING')
            return <BinocularIcon style={{ ...iconStyles, backgroundColor: indigo[300] }} />
        if (notificationCategory === 'PLAYER_DEVELOPMENT')
            return <SportsIcon style={{ ...iconStyles, backgroundColor: orange[700] }} />
        if (notificationCategory === 'GAME')
            return (
                <Avatar sx={{ ...iconStyles, backgroundColor: 'westchester.blue' }}>
                    <TeamLogo url={westchester?.url} name={null} width={16} height={16} />
                </Avatar>
            )
        if (notificationCategory === 'WORKOUT')
            return (
                <Avatar sx={{ ...iconStyles, backgroundColor: 'knicks.blue' }}>
                    <TeamLogo url={nyk?.url} name={null} width={16} height={16} />
                </Avatar>
            )
        if (notificationCategory === 'PLAYER_TIER')
            return <ListIcon style={{ ...iconStyles, backgroundColor: grey[700] }} />
        if (notificationCategory === 'INTEL_COVERAGE')
            return <GradingIcon style={{ ...iconStyles, backgroundColor: grey[700] }} />
        return <CommentIcon sx={{ ...iconStyles, backgroundColor: 'info.main' }} />
    }

    return (
        <Box
            sx={{ position: 'relative', backgroundColor: readAt ? 'common.white' : '#eef7fd' }}
            onMouseEnter={() => (isDesktop ? setShowControls(true) : undefined)}
            onMouseLeave={() => (isDesktop ? setShowControls(false) : undefined)}
        >
            {showControls && (
                <Box sx={{ position: 'absolute', right: '16px', bottom: '3px', zIndex: 1 }}>
                    <Controls horizontal>
                        <MenuItem
                            onClick={() => (readAt ? markAsUnread(recipient.id) : markAsRead(recipient.id))}
                            sx={{ gap: 1 }}
                        >
                            {readAt ? (
                                <VisibilityOffIcon sx={{ color: 'text.secondary' }} />
                            ) : (
                                <CheckIcon sx={{ color: 'text.secondary' }} />
                            )}
                            {readAt ? 'Mark as unread' : 'Mark as read'}
                        </MenuItem>
                    </Controls>
                </Box>
            )}
            <ListItemButton
                onClick={() => {
                    markAsRead(recipient.id)
                    handleClose()
                }}
                alignItems="flex-start"
            >
                <Link href={actionUrl || ''}>
                    <Box display="flex" flex={1}>
                        <Box display="flex" flexDirection="row" alignItems="flex-start">
                            <Box display="flex" alignItems="center" gap={1}>
                                <Box
                                    sx={{
                                        visibility: readAt ? 'hidden' : 'visible',
                                        bgcolor: 'info.main',
                                        width: 10,
                                        height: 10,
                                        borderRadius: '50%',
                                        top: '4px',
                                        position: 'relative',
                                    }}
                                />
                                <ListItemAvatar>
                                    <BadgeAvatar content={initials} badgeContent={notificationIcon(category)} />
                                </ListItemAvatar>
                            </Box>
                            <ListItemText
                                primary={
                                    <Typography
                                        component="p"
                                        variant="body2"
                                        sx={{
                                            '>p': { marginY: 0, display: 'inline' },
                                            b: { fontWeight: 'medium' },
                                            display: '-webkit-box',
                                            overflow: 'hidden',
                                            WebkitLineClamp: '4',
                                            WebkitBoxOrient: 'vertical',
                                        }}
                                        color={readAt ? 'text.secondary' : 'text.primary'}
                                        dangerouslySetInnerHTML={{
                                            __html: content || '',
                                        }}
                                    />
                                }
                                secondary={
                                    <Typography
                                        variant="body2"
                                        color={readAt ? 'text.secondary' : 'info.main'}
                                        fontWeight={readAt ? 'regular' : 'medium'}
                                        marginTop={1}
                                    >
                                        {fromNow(createdAt)}
                                    </Typography>
                                }
                            />
                        </Box>
                    </Box>
                </Link>
            </ListItemButton>
        </Box>
    )
}

export const NotificationSkeleton = (): JSX.Element => (
    <Box>
        <ListItemButton sx={{ pointerEvents: 'none' }} disabled alignItems="flex-start">
            <Box display="flex" alignItems="flex-start" flex={1}>
                <Box display="flex" flexDirection="row" alignItems="flex-start">
                    <Box display="flex" alignItems="center" gap={1}>
                        <Box
                            sx={{
                                width: 10,
                                height: 10,
                            }}
                        />
                        <ListItemAvatar>
                            <Skeleton variant="circular" width={46} height={46} />
                        </ListItemAvatar>
                    </Box>
                    <ListItemText
                        primary={<Skeleton variant="rectangular" width={250} height={65} />}
                        secondary={
                            <Box component="span" marginTop={1}>
                                <Skeleton width={85} height={20} />
                            </Box>
                        }
                    />
                </Box>
            </Box>
        </ListItemButton>
    </Box>
)

export default Notification
